/* corben-regular - latin-ext */
@font-face {
  font-family: "Corben";
  font-style: normal;
  font-weight: 400;
  src: local("Corben Regular"), local("Corben-Regular"),
    url("../src/fonts/corben-v13-latin-ext-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../src/fonts/corben-v13-latin-ext-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* signika-negative-regular - latin-ext */
@font-face {
  font-family: "Signika Negative";
  font-style: normal;
  font-weight: 400;
  src: local("Signika Negative Regular"), local("SignikaNegative-Regular"),
    url("../src/fonts/signika-negative-v10-latin-ext-regular.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../src/fonts/signika-negative-v10-latin-ext-regular.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* signika-negative-700 - latin-ext */
@font-face {
  font-family: "Signika Negative";
  font-style: normal;
  font-weight: 700;
  src: local("Signika Negative Bold"), local("SignikaNegative-Bold"),
    url("../src/fonts/signika-negative-v10-latin-ext-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../src/fonts/signika-negative-v10-latin-ext-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

* {
  border: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
}

:root {
  --primary-color: rgba(0, 0, 0, 0.87);
  --secondary-color: rgba(0, 0, 255, 0.87);
  /* --progress-color: #fafafa; */
  /* --text-color: #474747; */
  --inactive-color: #e4e4e4;
  --disabled-color: #f2f2f2;
  --background-color: #eeedee;

  --primary-font: "Corben";
  --secondary-font: "Signika Negative";
  --regular: 400;
  --bold: 700;

  --fontsize-h1: 36px;
  --fontsize-h2: 28px;
  --fontsize-h3: 24px;
  --fontsize-h4: 18px;
  --fontsize-h5: 15px;
  --fontsize-h6: 12px;
  --fontsize-h7: 10px;

  --max-width: 1080px;
}

a:visited {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  transition: 0.25s ease-in-out;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

.navbar {
  /* background-color: blue; */
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-nav,
.nav-item,
.nav-link,
.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;

  display: flex;
  align-self: center;
}

.nav-link {
  font-family: var(--secondary-font), sans-serif;
  font-weight: var(--regular);
}

/* .col-sm-7 {
  border: 0.5px solid red;
  outline: none;
}

.col {
  padding: 0;
  border: 0.5px solid blue;
  outline: none;
}

.container {
  border: 0.5px solid green;
  outline: none;
} */
